<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Version Search
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row dense class="mt-3">
          <v-col cols="12" sm="6" md="3">
            <v-text-field v-model="vendor" label="Vendor" hide-details @keydown.enter="getRecords"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field v-model="product" label="Product" hide-details @keydown.enter="getRecords"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field v-model="version" label="Version" hide-details @keydown.enter="getRecords"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-btn color="primary" class="mt-2" @click="getRecords">Search</v-btn>
          </v-col>
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col v-if="versions.length > 0" cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Vendor
                    </th>
                    <th class="text-center">
                      Product
                    </th>
                    <th>Version</th>
                    <th>Language</th>
                    <th>Update</th>
                    <th>Edition</th>
                    <th>Number of Vulnerabilities</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in versions" :key="item.name">
                    <td>{{ item.vendor }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ item.version }}</td>
                    <td>{{ item.language }}</td>
                    <td>{{ item.update }}</td>
                    <td>{{ item.edition }}</td>
                    <td>
                      <router-link
                        :to="
                          '/vulnerabilities-search/vendor-' +
                            item.vendor +
                            '/product-' +
                            item.product +
                            '/language-' +
                            item.language +
                            '/update-' +
                            item.update +
                            '/edition-' +
                            item.edition
                        "
                      >
                        {{ item.vulnerabilities }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col class="mt-2 flex-wrap" cols="12">
            <div style="width:200px" class="float-sm-left d-flex mb-2">
              <v-select
                v-model="itemsPerPage"
                style="width:85px"
                class="px-2 py-0 my-0 "
                :items="rowsPerPageItems"
                flat
                hide-details
                @change="
                  page = 1
                  getRecords()
                "
              />
              <span class="mt-2  text-body-1">
                {{ (page - 1) * itemsPerPage + 1 }}-{{
                  totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
                }}
                {{ "of" }} {{ totalResults }}
              </span>
            </div>
            <v-pagination
              v-model="page"
              class="d-flex"
              :length="Math.ceil(totalResults / itemsPerPage)"
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      versions: [],
      vendor: "",
      product: "",
      version: "",
      loading: false,
      year: 2021,
      month: 1,
      page: 1,
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ]
    }
  },
  computed: {},
  watch: {
    page() {
      this.getRecords()
    }
  },
  created() {
    this.getRecords()
  },
  methods: {
    getRecords() {
      //if (this.vendor != "" || this.product != "" || this.versions != "") {
      this.loading = true
      axios
        .get(
          "cve_list_versions/" +
            this.itemsPerPage +
            "/" +
            this.itemsPerPage * (this.page - 1) +
            "/" +
            (this.vendor ? this.vendor : "*") +
            "/" +
            (this.product ? this.product : "*") +
            "/" +
            (this.version ? this.version : "*") +
            ""
        )
        .then(response => {
          this.$log("products list ", response)
          this.versions = response.data.results
          this.totalResults = response.data.total
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }
    // }
  }
}
</script>
<style scoped>
td:first-child,
th:first-child {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
td {
  text-align: right !important;
}
td,
th {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}
th {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
